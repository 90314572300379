import { useContext } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { signUp } from '../../services/signup';
import { SignUpContext } from './signup';

const SigUpForm = () => {
   const { phone, setPhone, location } = useContext(SignUpContext);
   const { executeRecaptcha } = useGoogleReCaptcha();

   const getReCaptchaToken = async () => {
      if (!executeRecaptcha) return undefined;
      const token = await executeRecaptcha();
      return token;
   }

   const handleSubmit = async (e) => {
      e.preventDefault();

      const token = await getReCaptchaToken();
      if (!token) return;

      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData);
      data.recaptcha = token;
      data.location = location;
      data.agree = document.getElementById("agree").checked;
      data.nhsService = document.getElementById("nhsService").checked;
      data.fluTraining = document.getElementById("fluTraining").checked;
      data.otherTraining = document.getElementById("otherTraining").checked;
      data.newsletters = document.getElementById("newsletters").checked;
      data.newProductOffers = document.getElementById("newProductOffers").checked;

      const result = await signUp(data);
      if (result) e.target.reset();
   }

   return (
      <>
         <h5 className="mb-4">Your Contact Details</h5>

         <p>Please find your location by entering the name or postcode in the box below and select it from the list that comes up. If not on the list, please free-type your contact info in the boxes below:</p>

         <form className="contact-form needs-validation" onSubmit={handleSubmit}>
            <div className="row mb-3">
               <div className="col-lg-6">
                  <div className="border p-3">
                     <div className="row mb-1">
                        <label
                           htmlFor="firstName"
                           className="col-sm-4 col-form-label"
                        >
                           First Name *
                        </label>
                        <div className="col-sm-8">
                           <input
                              type="text"
                              id="firstName"
                              name="firstName"
                              className="form-control form-control-sm"
                           />
                        </div>
                     </div>

                     <div className="row mb-1">
                        <label
                           htmlFor="firstName"
                           className="col-sm-4 col-form-label"
                        >
                           Surname *
                        </label>
                        <div className="col-sm-8">
                           <input
                              type="text"
                              id="surname"
                              name="surname"
                              className="form-control form-control-sm"
                           />
                        </div>
                     </div>

                     <div className="row mb-1">
                        <label
                           htmlFor="email"
                           className="col-sm-4 col-form-label"
                        >
                           Email *
                        </label>
                        <div className="col-sm-8">
                           <input
                              type="email"
                              id="email"
                              name="email"
                              className="form-control form-control-sm"
                           />
                        </div>
                     </div>

                     <div className="row mb-1">
                        <label
                           htmlFor="mobile"
                           className="col-sm-4 col-form-label"
                        >
                           Mobile
                        </label>
                        <div className="col-sm-8">
                           <input
                              type="text"
                              id="mobile"
                              name="mobile"
                              className="form-control form-control-sm"
                           />
                        </div>
                     </div>

                     <div className="row mb-1">
                        <label
                           htmlFor="phone"
                           className="col-sm-4 col-form-label"
                        >
                           Phone
                        </label>
                        <div className="col-sm-8">
                           <input
                              type="text"
                              id="phone"
                              name="phone"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              className="form-control form-control-sm"
                           />
                        </div>
                     </div>

                     <p>
                        By signing up to SonarHealth you declare that the information you have provided is TRUE and you are genuine Advisor/Member of staff at the specified location.
                        Also you agree to The Sonar Informatics&nbsp;
                        <a href="https://www.sonarhealth.org/london-vacc/privacy-and-cookies" target="_blank" rel="noreferrer">
                           Terms of service
                        </a>
                        &nbsp;and&nbsp;
                        <a href="http://www.sonarhealth.org/london-vacc/privacy-and-cookies#mOne" target="_blank" rel="noreferrer">
                           Privacy policy
                        </a>
                     </p>

                     <div className="form-check mb-1">
                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="agree" name="agree" />
                        <label className="form-check-label" htmlFor="agree">
                           I confirm I have read and understood the above.
                        </label>
                     </div>
                  </div>
               </div>

               <div className="col-lg-6">
                  <div className="border p-3" style={{ minHeight: "507px" }}>
                     <h5 className="mb-3">GDPR Consent</h5>

                     <p>
                        We would like to send you occasional news and offers from Sonar Informatics as well as important alerts and material. For some communication we must be able to reach you for example, for NHS-related services. Please select below, what information you are okay for us to send to you. You can unsubscribe at any time.
                     </p>

                     <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultChecked={true} disabled={true} id="nhsService" name="nhsService" />
                        <label className="form-check-label" htmlFor="nhsService">
                           NHS Service alerts and service-related news, announcements and material
                        </label>
                     </div>

                     <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="fluTraining" name="fluTraining" />
                        <label className="form-check-label" htmlFor="fluTraining">
                           Flu Training, travel clinics and vaccinations
                        </label>
                     </div>

                     <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="otherTraining" name="otherTraining" />
                        <label className="form-check-label" htmlFor="otherTraining">
                           Other training opportunities
                        </label>
                     </div>

                     <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="newsletters" name="newsletters" />
                        <label className="form-check-label" htmlFor="newsletters">
                           Sonar newsletters
                        </label>
                     </div>

                     <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="newProductOffers" name="newProductOffers" />
                        <label className="form-check-label" htmlFor="newProductOffers">
                           New product offers
                        </label>
                     </div>
                  </div>
               </div>
            </div>

            <div className="d-flex justify-content-center align-items-center">
               <input
                  type="submit"
                  value="Submit"
                  className="btn btn-primary rounded-pill btn-send px-5 mx-2"
               />
            </div>
         </form>
      </>
   );
};

export default SigUpForm;
