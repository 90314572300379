import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { GetActivityByStpAndServiceId } from "../../services/flu";
import getOptDashboard2 from "./options/dashboard2";

const Dashboard2 = () => {
   const [serviceId, setServiceId] = useState(0);
   const [stp, setStp] = useState("North Central London");
   const [result, setResult] = useState({ Title: 'Activity by CCG', Data: [] });

   const fetchData = async (serviceId, stp) => {
      const result = await GetActivityByStpAndServiceId(serviceId, stp);
      if(result)setResult(result.d)
   }

   useEffect(() => {
      fetchData(serviceId, stp);
   }, [serviceId, stp]);

   return (
      <div className="border mb-3">
         <div className="p-2">
            <div className="row justify-content-between align-items-center">
               <div className="col-auto">
                  <div className="row">
                     <div className="col-auto">
                        <select
                           id="ddlArea2"
                           name="department"
                           className="form-select form-select-sm"
                           onChange={e => setStp(e.target.value)}
                           value={stp}
                        >
                           <option value="North Central London">NCL</option>
                           <option value="North East London">NEL</option>
                           <option value="North West London">NWL</option>
                           <option value="South East London">SEL</option>
                           <option value="South West London">SWL</option>
                        </select>
                     </div>
                     <div className="col-auto">
                        <select
                           id="ddlService2"
                           name="department"
                           className="form-select form-select-sm"
                           onChange={e => setServiceId(Number(e.target.value))}
                           value={serviceId}
                        >
                           <option value="0">
                              All Services
                           </option>
                           <option value="1652">London Flu</option>
                           <option value="1653">London PPV</option>
                           <option value="1651">National Flu</option>
                           <option value="9999">Total Flu (London + National)</option>
                        </select>
                     </div>
                  </div>
               </div>
               <div className="col-auto">
                  <h5 className="flu-title text-right mb-0">
                     {result.Title}
                  </h5>
               </div>
            </div>
         </div>

         <HighchartsReact
            options={getOptDashboard2(result.Data)}
            highcharts={Highcharts}
         />
      </div>
   );
};

export default Dashboard2;
