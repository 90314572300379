import ContactDetails from './contact-details';
import ContactForm from './contact-form';

const Contact = () => {
   return (
      <>
         <section className="wrapper bg-soft-primary">
            <div className="container pt-10 pb-12 pt-md-14 pb-md-16 text-center">
               <div className="row">
                  <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
                     <h1 className="display-1 mb-3">Get in touch</h1>
                     <p className="lead px-xxl-10">Have any questions? Reach out to us from our contact form and we will get back to you shortly.</p>
                  </div>
               </div>
            </div>
         </section>

         <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
               <div className="row">
                  <div className="col-xl-10 mx-auto">
                     <div className="row gy-10 gx-lg-8 gx-xl-12">
                        <div className="col-lg-6">
                           <ContactDetails />
                        </div>
                        <div className="col-lg-6">
                           <h3 className="display-5">Write to us</h3>
                           <p>To send us a message, please fill-in the following form</p>
                           <ContactForm />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Contact;
