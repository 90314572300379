const getOptDashboard5 = (chartData) => {
   let season1 = [];
   let season2 = [];
   let season3 = [];
   let season4 = [];
   let season5 = [];
   let season6 = [];

   chartData.forEach((item) => {
      if (item.Serie === "1") season1.push(item.Value);
      else if (item.Serie === "2") season2.push(item.Value);
      else if (item.Serie === "3") season4.push(item.Value);
      else if (item.Serie === "4") season3.push(item.Value);
      else if (item.Serie === "5") season5.push(item.Value);
      else if (item.Serie === "6") season6.push(item.Value);
   });

   return {
      credits: {
         enabled: false,
      },
      chart: {
         type: "area",
         spacingBottom: 30,
      },
      title: {
         text: null,
      },
      xAxis: {
         categories: ["Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"],
      },
      yAxis: {
         title: {
            text: "Number of Jabs",
         },
         labels: {
            formatter: function () {
               return this.value;
            },
         },
      },
      tooltip: {
         formatter: function () {
            return (
               "<b>" + this.series.name + "</b><br/>" + this.x + ": " + this.y
            );
         },
      },
      plotOptions: {
         area: {
            fillOpacity: 0.5,
         },
      },
      series: [
         {
            name: "Season 2017-18",
            data: season4,
         },
         {
            name: "Season 2018-19",
            data: season3,
         },
         {
            name: "Season 2019-20",
            data: season2,
         },
         {
            name: "Season 2020-21",
            data: season1,
         },
         {
            name: "Season 2021-22",
            data: season5,
         },
         {
            name: "Season 2022-23",
            data: season6,
         },
      ],
      exporting: { enabled: true },
   };
};

export default getOptDashboard5;