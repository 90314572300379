const PocCovid19Offer = () => {
   return (
      <>
         <div className="row">
            <div className="col-lg-12 col-xl-12 col-xxl-12">
               <h2 className="fs-15 text-uppercase text-muted mb-3">What We Offer?</h2>
               <h3 className="display-4 mb-9">The service we offer is specifically designed to meet your needs.</h3>
            </div>
         </div>
         <div className="row gx-md-8 gy-8">
            <div className="col-md-6 col-lg-3">
               <div className="icon btn btn-block btn-lg btn-soft-yellow disabled mb-5"> <i className="uil uil-phone-volume"></i> </div>
               <h4>24/7 Support</h4>
               <p className="mb-3">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Cras justo.</p>
               <a href="!#" className="more hover link-yellow">Learn More</a>
            </div>
            <div className="col-md-6 col-lg-3">
               <div className="icon btn btn-block btn-lg btn-soft-red disabled mb-5"> <i className="uil uil-shield-exclamation"></i> </div>
               <h4>NHS BSA Payments</h4>
               <p className="mb-3">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Cras justo.</p>
               <a href="!#" className="more hover link-red">Learn More</a>
            </div>
            <div className="col-md-6 col-lg-3">
               <div className="icon btn btn-block btn-lg btn-soft-leaf disabled mb-5"> <i className="uil uil-laptop-cloud"></i> </div>
               <h4>Video consultations</h4>
               <p className="mb-3">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Cras justo.</p>
               <a href="!#" className="more hover link-leaf">Learn More</a>
            </div>
            <div className="col-md-6 col-lg-3">
               <div className="icon btn btn-block btn-lg btn-soft-blue disabled mb-5"> <i className="uil uil-chart-line"></i> </div>
               <h4>Reporting</h4>
               <p className="mb-3">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Cras justo.</p>
               <a href="!#" className="more hover link-blue">Learn More</a>
            </div>
         </div>
      </>
   );
};

export default PocCovid19Offer;
