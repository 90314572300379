import { ToastContainer } from 'react-toastify';
import Header from "./Header";
import Footer from "./Footer";
import theme from "./js/theme";
import useEffectOnce from "../../helpers/hooks/useEffectOnce";

const Layout = ({ children }) => {
   useEffectOnce(() => {
      theme.init();
   }, []);

   return (
      <>
         <div className="content-wrapper">
            <Header />
            <main>{ children }</main>
            <ToastContainer />
         </div>
         <Footer />
      </>
   )

}

export default Layout;
