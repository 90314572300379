const CPCSUserGuidesCPCS = () => {
   return (
      <>
         <h5 className="mb-4">USER GUIDES FOR CPCS</h5>

         <ul className="ps-4 mb-0">
            <li className="cpcs-list-item">
               ●&nbsp;
               <a href="https://www.youtube.com/watch?v=k22S4G83Q6Y" className='cpcs-link' target="_blank" rel="noreferrer">
                  Video guide
               </a>
            </li>

            <li className="cpcs-list-item">
               ●&nbsp;
               <a href="/docs/user-guides/cpcs/cpcs-user-guide-2020v1.4.pdf" className='cpcs-link'>
                  User guide
               </a>
            </li>

            <li className="cpcs-list-item">
               <a href="/docs/user-guides/cpcs/mys-claims-user-guidev1.0.pdf" className='cpcs-link'>
                  MYS claims - User guide
               </a>
            </li>

            <li className="cpcs-list-item">
               ●&nbsp;
               <a href="https://www.youtube.com/watch?v=eJ-ghXYK6M8" className='cpcs-link' target="_blank" rel="noreferrer">
                  CPCS - SCR 1 Click
               </a>
            </li>

            <li className="cpcs-list-item">
               ●&nbsp;
               <a href="https://www.youtube.com/watch?v=K5syIwbFgt0" className='cpcs-link' target="_blank" rel="noreferrer">
                  SCR 1Click - Setup
               </a>
            </li>
         </ul>
      </>
   );
};

export default CPCSUserGuidesCPCS;
