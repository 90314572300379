import { toast } from 'react-toastify';

const isValidUser = ({ firstName, surname, email, mobile, phone, recaptcha, location, agree }) => {

   if (!firstName || !firstName.trim()) {
      toast.warning('The First Name field is required.');
   } else if (!surname || !surname.trim()) {
      toast.warning('The Surname is required!');
   } else if (!email || !email.trim()) {
      toast.warning('The Email Field field is required.');
   } else if (!mobile || !mobile.trim()) {
      toast.warning('The Mobile field is required.');
   } else if (!phone || !phone.trim()) {
      toast.warning('The Phone field is required.');
   } else if (!recaptcha || !recaptcha.trim()) {
      toast.warning('The Recaptcha token is required.');
   } else if (!location?.Name) {
      toast.warning('The Location Name field is required.');
   } else if (!location?.OdsCode) {
      toast.warning('The Location ODSCode field is required.');
   } else if (!location?.Address.PostCode) {
      toast.warning('The Location PostCode field is required.');
   } else if (!agree) {
      toast.warning('You must accept the terms.');
   } else {
      return true;
   }

   return false;
}

export const signUp = async (payload) => {
   try {
      if (!isValidUser(payload))
         return false;
      const request = {
         FirstName: payload.firstName,
         Surname: payload.surname,
         Email: payload.email,
         Mobile: payload.mobile,
         Phone: payload.phone,
         Location: {
            Name: payload.location?.Name,
            ODSCode: payload.location?.OdsCode,
            PostCode: payload.location?.Address.PostCode,
         }
      }

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}users/signup`
      const fecthParams = {
         method: 'post',
         headers: {
            'Content-Type': 'application/json',
            'G-Recaptcha-Response': payload.recaptcha
         },
         body: JSON.stringify(request),
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();

      if (data.errors && data.errors.length > 0) {
         toast.warning(data.errors[0].msg);
         return false;
      }

      toast.success('Signing up successfully!');
      return true;

   } catch (error) {
      console.log(error);
      toast.error('Sign up failed!');
      return false;
   }
}
