const getOptDashboard1 = (chartData) => {
   const data = chartData.map((item) => ({ name: item.Name, y: item.Value }));

   return {
      credits: {
         enabled: false,
      },
      chart: {
         plotBackgroundColor: null,
         plotBorderWidth: null,
         plotShadow: false,
         type: "pie",
         options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
         },
      },
      title: {
         text: "",
         style: {
            display: "none",
         },
      },
      tooltip: {
         pointFormat: "{series.name}: <b>{point.y}</b>",
      },
      plotOptions: {
         pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
               enabled: true,
               format: "{point.name} ({point.percentage:.1f}%)",
            },
            showInLegend: false,
         },
      },
      series: [
         {
            name: "Jabs",
            colorByPoint: true,
            data: data,
         },
      ],
      exporting: { enabled: true },
   };
};

export default getOptDashboard1;