import { toast } from 'react-toastify';

const isValidCPCSRegistry = ({ type, contactName, contactEmail, organisation, organisationTradingName, locations }) => {
   const validLocations = locations.filter(location => {
      return (
         location.name && location.name.trim() &&
         location.odsCode && location.odsCode.trim() &&
         location.postCode && location.postCode.trim()
      );
   });

   if (!type || !type.trim()) {
      toast.warning('The Type field is required.');
   } else if (!contactName || !contactName.trim()) {
      toast.warning('The Contact Name is required.');
   } else if (!contactEmail || !contactEmail.trim()) {
      toast.warning('The Contact Field field is required.');
   } else if (!organisation || !organisation.trim()) {
      toast.warning('The Organisation field is required.');
   } else if (!organisationTradingName || !organisationTradingName.trim()) {
      toast.warning('The Organisation Trading Name field is required.');
   } else if (validLocations.length === 0) {
      toast.warning('The Locations list field are required.');
   } else {
      return true;
   }

   return false;
}

export const saveCPCSRegistry = async (payload) => {
   try {
      if (!isValidCPCSRegistry(payload))
         return false;

      const request = {
         Type: payload.type,
         ContactName: payload.contactName,
         ContactEmail: payload.contactEmail,
         Organisation: payload.organisation,
         OrganisationTradingName: payload.organisationTradingName,
         Locations: payload.locations.map(location => ({
            Name: location.name,
            ODSCode: location.odsCode,
            PostCode: location.postCode
         })),
      }

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}cpcs/saveregistry`
      const fecthParams = {
         method: 'post',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(request),
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();

      if (data.errors && data.errors.length > 0) {
         toast.warning(data.errors[0].msg);
         return false;
      }

      toast.success('Registry saved successfully!');
      return true;

   } catch (error) {
      toast.error('Save Registry failed!');
      return false;
   }
}
