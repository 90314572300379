import { useEffect } from 'react';

const Favorites = () => {
   useEffect(() => {
      document.title = '404 Page not found';
      return () => document.title = 'SonarHealth.org';
   });

   return (
      <>
         <section className="wrapper bg-light">
            <div className="container pt-12 pt-md-14 pb-14 pb-md-16">
               <div className="row">
                  <div className="col-lg-9 col-xl-8 mx-auto">
                     <figure className="mb-10">
                        <img className="img-fluid" src="./assets/img/illustrations/404.png" srcSet="./assets/img/illustrations/404@2x.png 2x" alt="" />
                     </figure>
                  </div>

                  <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                     <h1 className="mb-3">Oops! Page Not Found.</h1>
                     <p className="lead mb-7 px-md-12 px-lg-5 px-xl-7">The page you are looking for is not available or has been moved. Try a different page or go to homepage with the button below.</p>
                     <a href={ `${process.env.PUBLIC_URL}/` } className="btn btn-primary rounded-pill">Go to Homepage</a>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Favorites;
