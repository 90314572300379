import { Link } from "react-router-dom";

const CPCSNotes = () => {
   return (
      <>
         <h5 className="mb-4">IMPORTANT NOTES FOR SONAR USERS OF THE CPCS MODULE</h5>

         <p>Ensure that all pharmacists and locums have access to Sonar. To register for Sonar click <Link to="/signup">here</Link>.</p>
         <p className="mb-0">We'll need to confirm your access to the system.</p>
      </>
   );
};

export default CPCSNotes;
