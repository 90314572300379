const getOptDashboard3 = (chartData) => {
   const categories = chartData.map((item) => item.Name);
   const data = chartData.map((item) => item.Value);

   return {
      credits: {
         enabled: false,
      },
      chart: {
         type: "bar",
      },
      title: {
         text: "",
         style: {
            display: "none",
         },
      },
      xAxis: {
         categories: categories,
         title: {
            text: null,
         },
      },
      yAxis: {
         min: 0,
         title: {
            text: "Number of Jabs",
            align: "high",
         },
         labels: {
            overflow: "justify",
         },
      },
      tooltip: {
         valueSuffix: " jabs",
      },
      plotOptions: {
         bar: {
            dataLabels: {
               enabled: true,
            },
         },
      },
      series: [
         {
            showInLegend: false,
            name: "Season 2021-22",
            data: data,
         },
      ],
      exporting: { enabled: true },
   };
};

export default getOptDashboard3