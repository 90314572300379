const getOptDashboard4 = (chartData) => {
   const categories = chartData.map((item) => item.Name);
   const data = chartData.map((item) => item.Value);

   return {
      credits: {
         enabled: false,
      },
      chart: {
         type: "line",
      },
      title: {
         text: "",
         style: {
            display: "none",
         },
      },
      xAxis: {
         categories: categories,
      },
      yAxis: {
         title: {
            text: "Number of Jabs",
         },
      },
      plotOptions: {
         line: {
            dataLabels: {
               enabled: true,
            },
            enableMouseTracking: false,
         },
      },
      series: [
         {
            name: "Daily jabs",
            data: data,
         },
      ],
      exporting: { enabled: true },
   };
};

export default getOptDashboard4