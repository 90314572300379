const CPCSTabGPs = () => {
   return (
      <>
         <p>Sonar is providing a GP CPCS service in which GP practices can refer their patients to community pharmacies for minor illness conditions. We are providing the platform for GP’s to create the referral which is received by the pharmacy directly through Sonar (Text and Email in addition). Once your patient attends their consultation the outcome is available directly.</p>
         <p>The GP CPCS service is free for GPs and we are also providing training to all GP non-clinical/reception staff on the use of our IT platform as well as the minor illness conditions listed.</p>
         <span className="badge bg-secondary mr-1">No set up fee</span>
         <span className="badge bg-secondary mr-1">No contract period</span>
      </>
   );
};

export default CPCSTabGPs;
