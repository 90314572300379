import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { GetActivityByAreaAndServiceId } from "../../services/flu";
import getOptDashboard1 from "./options/dashboard1";

const Dashboard1 = () => {
   const [serviceId, setServiceId] = useState(0);
   const [result, setResult] = useState({ Title: 'Activity by area', Data: [] });

   const fetchData = async (serviceId) => {
      const result = await GetActivityByAreaAndServiceId(serviceId);
      if(result)setResult(result.d)
   }

   useEffect(() => {
      fetchData(serviceId);
   }, [serviceId]);

   return (
      <div className="border mb-3">
         <div className="p-2">
            <div className="row justify-content-between align-items-center">
               <div className="col-auto">
                  <select
                     id="ddlService1"
                     name="department"
                     className="form-select form-select-sm"
                     onChange={e => setServiceId(Number(e.target.value))}
                     value={serviceId}
                  >
                     <option value="0">
                        All Services
                     </option>
                     <option value="1652">London Flu</option>
                     <option value="1653">London PPV</option>
                     <option value="1651">National Flu</option>
                     <option value="9999">Total Flu (London + National)</option>
                  </select>
               </div>
               <div className="col-auto">
                  <h5 className="flu-title text-right mb-0">
                     {result.Title}
                  </h5>
               </div>
            </div>
         </div>

         <HighchartsReact
            options={getOptDashboard1(result.Data)}
            highcharts={Highcharts}
         />
      </div>
   );
};

export default Dashboard1;
