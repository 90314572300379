import { Tabs, Tab } from 'react-bootstrap';
import CPCSTabGPs from './cpcs-tab-gps';
import CPCSTabPharmacies from './cpcs-tab-pharmacies';

const CPCSTabs = () => {
   return (
      <>
         <div className='cpcs-tabs border'>
            <Tabs defaultActiveKey="pharmacies">
               <Tab eventKey="pharmacies" title="PHARMACIES">
                  <CPCSTabPharmacies />
               </Tab>
               <Tab eventKey="gps" title="GPs">
                  <CPCSTabGPs />
               </Tab>
            </Tabs>
         </div>

         <p className='pt-4'>Related documents:</p>
         <p className='px-3'>● <a href="/docs/cpcs-sla.pdf" className='cpcs-link'>SLA</a></p>
         <p>We have been working with pharmacies and GP's to improve the range of services we provide and these are all available under <span className='fw-bold'>one platform</span>.</p>

         <p>Use Sonar as your CPCS provider:</p>
         <p className='mb-0'>
            ● Direct panel access - manage your consultations under one platform<br />
            ● Text message alerts - add up to 2 mobile numbers to receive text message notifications for incoming referrals<br />
            ● Email notifications<br />
            ● IT support
         </p>
      </>
   );
};

export default CPCSTabs;
