import { toast } from 'react-toastify';

const isValidPocCovid19Order = ({ email, name, jobTitle, phone, originalODSCode, vaccinationSiteName, serviceType, confirm }) => {
   if (!email || !email.trim()) {
      toast.warning('The Email field is required.');
   } else if (!name || !name.trim()) {
      toast.warning('The Name field is required.');
   } else if (!jobTitle || !jobTitle.trim()) {
      toast.warning('The Job Title field is required.');
   } else if (!phone || !phone.trim()) {
      toast.warning('The Phone field required.');
   } else if (!originalODSCode || !originalODSCode.trim()) {
      toast.warning('The Original ODS Code field is required.');
   } else if (!vaccinationSiteName || !vaccinationSiteName.trim()) {
      toast.warning('The Vaccination Site Name is required.');
   } else if (!serviceType || !serviceType.trim()) {
      toast.warning('The Service Type is required.');
   } else if (!confirm || !confirm.trim()) {
      toast.warning('The Confirmation field is required.');
   } else {
      return true;
   }

   return false;
}

export const savePocCovid19Order = async (payload) => {
   try {
      if (!isValidPocCovid19Order(payload))
      return false;

      const request = {
         Email: payload.email,
         Name: payload.name,
         JobTitle: payload.jobTitle,
         Phone: payload.phone,
         OriginalODSCode: payload.originalODSCode,
         VaccinationSiteName: payload.vaccinationSiteName,
         ServiceType: payload.serviceType,
         Confirm: payload.confirm,
      }

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}poccovid19/saveorder`
      const fecthParams = {
         method: 'post',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(request),
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();

      if (data.errors && data.errors.length > 0) {
         toast.warning(data.errors[0].msg);
         return false;
      }

      toast.success('Order saved successfully!');
      return true;

   } catch (error) {
      toast.error('Save Order failed!');
      return false;
   }
}
