import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { GetDailyVaccinationsByServiceId } from "../../services/flu";
import getOptDashboard4 from "./options/dashboard4";

const Dashboard4 = () => {
   const [serviceId, setServiceId] = useState(1652);
   const [monthStr, setMonthStr] = useState("01");
   const [result, setResult] = useState({ Title: 'Daily Vacciantions', Data: [] });

   const fetchData = async (serviceId, monthStr) => {
      const month = Number(monthStr);
      const year = month < 4 ? 2022 : 2021;
      const result = await GetDailyVaccinationsByServiceId(serviceId, month, year);
      if(result)setResult(result.d);
   };

   useEffect(() => {
      fetchData(serviceId, monthStr);
   }, [serviceId, monthStr]);

   return (
      <div className="border">
         <div className="p-2">
            <div className="row justify-content-between align-items-center">
               <div className="col-auto">
                  <div className="row">
                     <div className="col-auto">
                        <select
                           id="ddlMonth4"
                           name="department"
                           className="form-select form-select-sm"
                           onChange={e => setMonthStr(e.target.value)}
                           value={monthStr}
                        >
                           <option value="01">JAN 2022</option>
                           <option value="12">DEC 2021</option>
                           <option value="11">NOV 2021</option>
                           <option value="10">OCT 2021</option>
                           <option value="09">SEP 2021</option>
                        </select>
                     </div>
                     <div className="col-auto">
                        <select
                           id="ddlService4"
                           name="department"
                           className="form-select form-select-sm"
                           onChange={e => setServiceId(Number(e.target.value))}
                           value={serviceId}
                        >
                           <option value="0">All Services</option>
                           <option value="1652">London Flu</option>
                           <option value="1653">London PPV</option>
                           <option value="1651">National Flu</option>
                           <option value="9999">
                              {result.Title}
                           </option>
                        </select>
                     </div>
                  </div>
               </div>
               <div className="col-auto">
                  <h5 className="flu-title text-right mb-0">
                     Daily vaccinations
                  </h5>
               </div>
            </div>
         </div>

         <HighchartsReact
            options={getOptDashboard4(result.Data)}
            highcharts={Highcharts}
         />
      </div>
   );
};

export default Dashboard4;
