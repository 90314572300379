import PocCovid19SignUpDetails from "./poc-covid19-signup-details";
import PocCovid19SignUpForm from "./poc-covid19-signup-form";

const PocCovid19SignUp = () => {
   return (
      <>
         <h2 className="mb-5">Sonar POC Covid and flu | Order Form</h2>
         <div className="card">
            <div className="card-body">
               <div className="row">
                  <div className="col-lg-6">
                     <PocCovid19SignUpDetails />
                  </div>
                  <div className="col-lg-6">
                     <PocCovid19SignUpForm />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default PocCovid19SignUp;
