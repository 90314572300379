import Headhesive from "headhesive";
import { Offcanvas } from "bootstrap"

var theme = {
   /**
    * Theme's components/functions list
    * Comment out or delete the unnecessary component.
    * Some components have dependencies (plugins).
    * Do not forget to remove dependency from src/js/vendor/ and recompile.
    */
   init: function () {
      theme.stickyHeader();
      theme.pageProgress();
      theme.offCanvas();
   },
   /**
    * Sticky Header
    * Enables sticky behavior on navbar on page scroll
    * Requires assets/js/vendor/headhesive.min.js
   */
   stickyHeader: () => {
      var navbar = document.querySelector(".navbar");
      if (navbar == null) return;
      var options = {
         offset: 350,
         offsetSide: 'top',
         classes: {
            clone: 'navbar-clone fixed',
            stick: 'navbar-stick',
            unstick: 'navbar-unstick',
         },
         onStick: function () {
            var navbarClonedClass = this.clonedElem.classList;
            if (navbarClonedClass.contains('transparent') && navbarClonedClass.contains('navbar-dark')) {
               this.clonedElem.className = this.clonedElem.className.replace("navbar-dark", "navbar-light");
            }
         }
      };
      new Headhesive('.navbar', options);
   },
   /**
    * Page Progress
    * Shows page progress on the bottom right corner of pages
    */
   pageProgress: () => {
      var progressWrap = document.querySelector('.progress-wrap');
      var progressPath = document.querySelector('.progress-wrap path');
      var pathLength = progressPath.getTotalLength();
      var offset = 50;
      if (progressWrap != null) {
         progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
         progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
         progressPath.style.strokeDashoffset = pathLength;
         progressPath.getBoundingClientRect();
         progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
         window.addEventListener("scroll", function (event) {
            var scroll = document.body.scrollTop || document.documentElement.scrollTop;
            var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            var progress = pathLength - (scroll * pathLength / height);
            progressPath.style.strokeDashoffset = progress;
            var scrollElementPos = document.body.scrollTop || document.documentElement.scrollTop;
            if (scrollElementPos >= offset) {
               progressWrap.classList.add("active-progress")
            } else {
               progressWrap.classList.remove("active-progress")
            }
         });
         progressWrap.addEventListener('click', function (e) {
            e.preventDefault();
            window.scroll({
               top: 0,
               left: 0,
               behavior: 'smooth'
            });
         });
      }
   },
   /**
    * Offcanvas
    * Enables offcanvas-nav, closes offcanvas on anchor clicks, focuses on input in search offcanvas
    */
   offCanvas: () => {
      const navOffCanvasBtn = document.querySelectorAll(".offcanvas-nav-btn");
      const navOffCanvas = document.querySelector('.navbar:not(.navbar-clone) .offcanvas-nav');
      const bsOffCanvas = new Offcanvas(navOffCanvas, { scroll: true });
      const scrollLink = document.querySelectorAll('.onepage .navbar li a.scroll');
      const searchOffcanvas = document.getElementById('offcanvas-search');
      navOffCanvasBtn.forEach(e => {
         e.addEventListener('click', event => {
            bsOffCanvas.show();
         })
      });
      scrollLink.forEach(e => {
         e.addEventListener('click', event => {
            bsOffCanvas.hide();
         })
      });
      if (searchOffcanvas != null) {
         searchOffcanvas.addEventListener('shown.bs.offcanvas', function () {
            document.getElementById("search-form").focus();
         });
      }
   }
};

export default theme;
