import CPCSForm from './cpcs-form';
import CPCSNotes from './cpcs-notes';
import CPCSPSNCFAQs from './cpcs-psnc-faqs';
import CPCSSonarFAQs from './cpcs-sonar-faqs';
import CPCSTabs from './cpcs-tabs';
import CPCSUserGuidesCPCS from './cpcs-user-guides-cpcs';
import CPCSUserGuidesGPCPCS from './cpcs-user-guides-gpcpcs';

const CPCS = () => {
   return (
      <>
         <section className="wrapper bg-soft-primary">
            <div className="container p-4 text-center">
               <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
                     <h1 className="display-1 mb-3">
                        NHS Community Pharmacist Consultation Service (CPCS)
                     </h1>
                  </div>
               </div>
            </div>
         </section>

         <section className="wrapper bg-light py-3">
            <div className="container py-4 bg-white">
               <CPCSTabs />
            </div>
         </section>

         <section className="wrapper bg-light py-3">
            <div className="container py-4 bg-white">
               <CPCSNotes />
            </div>
         </section>

         <section className="wrapper bg-light py-3">
            <div className="container py-4 bg-white">
               <CPCSUserGuidesCPCS />
            </div>
         </section>

         <section className="wrapper bg-light py-3">
            <div className="container py-4 bg-white">
               <CPCSUserGuidesGPCPCS />
            </div>
         </section>

         <section className="wrapper bg-light py-3">
            <div className="container py-4 bg-white">
               <CPCSPSNCFAQs />
            </div>
         </section>

         <section className="wrapper bg-light py-3">
            <div className="container py-4 bg-white">
               <CPCSSonarFAQs />
            </div>
         </section>

         <section className="wrapper bg-light py-3">
            <div className="container py-4 bg-white">
               <CPCSForm />
            </div>
         </section>
      </>
   );
};

export default CPCS;
