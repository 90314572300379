import { Accordion } from 'react-bootstrap'

const CPCSSonarFAQs = () => {
   return (
      <>
         <h5 className="mb-4">Sonar FAQs</h5>

         <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
               <Accordion.Header>
                  Why can't I advance to the next page when I try to complete the consultation?
               </Accordion.Header>
               <Accordion.Body>
                  Remember to fill in all the blank spaces and tick the appropriate boxes.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
               <Accordion.Header>
                  How can I add a medicine entry to the consultation?
               </Accordion.Header>
               <Accordion.Body>
                  There is a link called “Add medicine” below the “Medicine(s) given” text box. Once you select it a pop-up box appears so you can type in the medicine and select it from the dropdown list.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
               <Accordion.Header>
                  The medicine I supplied is not available to select on the dropdown list.
               </Accordion.Header>
               <Accordion.Body>
                  Type the name of the medicine or part of the name and type the dose so you can see a filtered list. Wait until the page loads the list. Select the medicine supplied.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
               <Accordion.Header>
                  How do I add presenting complaints?
               </Accordion.Header>
               <Accordion.Body>
                  You need to click on the link “Add presenting complaint(s)” below the “Presenting complaint” text box. Select the complaints by ticking the checkboxes and/or type an unlisted complaint. Choose “Submit to add”.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
               <Accordion.Header>
                  Why am I not seeing the patient under the 111 NHS referrals?
               </Accordion.Header>
               <Accordion.Body>
                  Click on the link “View All” under “Referrals” on the top left-hand side of the screen.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
               <Accordion.Header>
                  My page is not loading properly. What is wrong?
               </Accordion.Header>
               <Accordion.Body>
                  If you are accessing the CPCS with Internet Explorer browser, try to access with another browser like Chrome or Firefox. If the problem continues, log out and login again.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
               <Accordion.Header>
                  How do I access CPCS?
               </Accordion.Header>
               <Accordion.Body>
                  There is a module on your Sonar account under NHS Services, called Pharmacy CPCS. Click the link to be redirected to the CPCS platform.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
               <Accordion.Header>
                  How do I get CPCS referrals?
               </Accordion.Header>
               <Accordion.Body>
                  The patient will dial 111 and if the case is a minor illness, he/she will be forwarded to the nearest pharmacy.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
               <Accordion.Header>
                  How do I get GP CPCS referrals?
               </Accordion.Header>
               <Accordion.Body>
                  Sonar is also providing a GP CPCS service free for GPs.
                  We provide virtual training to all GP non-clinical/reception staff regarding the use of our IT platform as well as the minor
                  illness conditions that can and cannot be referred.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
               <Accordion.Header>
                  How do I know when I get a new referral?
               </Accordion.Header>
               <Accordion.Body>
                  The notifications of new referrals will come to all the emails provided when you registered to use Sonar for CPCS.
                  You can also add a mobile number to your account to receive a text message alert.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
               <Accordion.Header>
                  What if a patient is not coming to the pharmacy anymore?
               </Accordion.Header>
               <Accordion.Body>
                  If you haven't finished a consultation for whatever reason, click on the patient's name and then select “Unable to complete”
                  and select the reason for closing the consultation.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
               <Accordion.Header>
                  How can different pharmacists access CPCS at my pharmacy?
               </Accordion.Header>
               <Accordion.Body>
                  Each pharmacist will log into their Sonar account and access the Pharmacy CPCS module.
                  If they have added your pharmacy to their Sonar account, they will be able to access the referrals.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
               <Accordion.Header>
                  What should I write within the “Clinical Narrative”?
               </Accordion.Header>
               <Accordion.Body>
                  If you have made a clinical record, examination or other relevant notes, include them within this section.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
               <Accordion.Header>
                  What should I write on “Actions taken to date”?
               </Accordion.Header>
               <Accordion.Body>
                  If the patients have been taking some medicine or done something by themselves
                  regarding the illness they are experiencing, include it here.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
               <Accordion.Header>
                  I can't find the patient's record when adding a new record. What do I do?
               </Accordion.Header>
               <Accordion.Body>
                  If the PDS search did not produce any results, you must input the patient's details manually.
                  Search for the patient by first name, last name, DOB and gender.
               </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
               <Accordion.Header>
                  How do I know if the consultation is completed?
               </Accordion.Header>
               <Accordion.Body>
                  The record status will display a green circle indicating your submission was successful.
               </Accordion.Body>
            </Accordion.Item>
         </Accordion>
      </>
   );
};

export default CPCSSonarFAQs;
