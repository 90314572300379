import { savePocCovid19Order } from "../../services/pocCovid19";

const PocCovid19SignUpForm = () => {
   const handleSubmit = async (e) => {
      e.preventDefault();

      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData);

      const result = await savePocCovid19Order(data);
      if (result) e.target.reset();
   }

   return (
      <form className="contact-form needs-validation" onSubmit={ handleSubmit }>
         <div className="form-floating mb-2">
            <input type="email" id="email" name="email" className="form-control form-control-sm" placeholder="jane.doe@example.com" />
            <label htmlFor="email">Email *</label>
         </div>

         <div className="form-floating mb-2">
            <input type="text" id="name" name="name" className="form-control form-control-sm" placeholder="Subject" />
            <label htmlFor="name">Name *</label>
         </div>

         <div className="form-floating mb-2">
            <input type="text" id="jobTitle" name="jobTitle" className="form-control form-control-sm" placeholder="Job Title" />
            <label htmlFor="jobTitle">Job Title *</label>
         </div>

         <div className="form-floating mb-2">
            <input type="text" id="phone" name="phone" className="form-control form-control-sm" placeholder="Phone" />
            <label htmlFor="phone">Phone *</label>
         </div>

         <div className="form-floating mb-2">
            <input type="text" id="originalODSCode" name="originalODSCode" className="form-control form-control-sm" placeholder="Phone" />
            <label htmlFor="originalODSCode">Original ODS Code *</label>
         </div>

         <div className="form-floating mb-2">
            <input type="text" id="vaccinationSiteName" name="vaccinationSiteName" className="form-control form-control-sm" placeholder="Phone" />
            <label htmlFor="vaccinationSiteName">Vaccination Site Name *</label>
         </div>

         <p className='mt-4 mb-1'>
            <strong>* Sonar Health - POC ( Covid and Flu ) Services</strong>
         </p>

         <p className='mt-0'>
            The services relates to the Sonar point-of-care solution - this solution can be used to record Covid and Flu vaccinations. The service is accessed via the internet, and is hosted by Sonar. The services has been designed to adhere to NHS Digital's specifications. There are no hardware products or services included (eg, laptops, barcode scanners etc).
         </p>

         <p className='mt-4 mb-2'>
            <strong>Types of Service delivered at your clinic</strong>
         </p>

         <div className="form-check mb-0">
            <input type="radio" id="serviceTypeNHS" name="serviceType" className="form-check-input" value="NHS Funded" />
            <label htmlFor="serviceTypeNHS" className="form-check-label" style={ { fontSize: "0.875rem" } }>
               NHS funded - National Vaccination Programme service ( Covid Flu and PPV )
            </label>
         </div>

         <div className="form-check mb-0">
            <input type="radio" id="serviceTypePrivately" name="serviceType" className="form-check-input" value="Privately Funded Service" />
            <label htmlFor="serviceTypePrivately" className="form-check-label" style={ { fontSize: "0.875rem" } }>
               Privately funded service
            </label>
         </div>

         <p className='mt-4 mb-2'>
            I confirm that I, on behalf of my Pharmacy vaccination site, want to proceed to use Sonar POC as my site(s)' point of care solution for Covid vaccinations.
         </p>

         <div className="form-check form-check-inline">
            <input type="radio" id="confirmYes" name="confirm" className="form-check-input" value="Yes" />
            <label htmlFor="confirmYes" className="form-check-label" style={ { fontSize: "0.875rem" } }>
               Yes
            </label>
         </div>
         <div className="form-check form-check-inline">
            <input type="radio" id="confirmNo" name="confirm" className="form-check-input" value="No" />
            <label htmlFor="confirmNo" className="form-check-label" style={ { fontSize: "0.875rem" } }>
               No
            </label>
         </div>

         <div className="d-flex align-items-center mt-4">
            Click on <input type="submit" className="btn btn-primary rounded-pill btn-send mx-2" value="Submit" /> to confirm details.
         </div>
      </form >
   );
};

export default PocCovid19SignUpForm;
