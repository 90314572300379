import { getRoles, getToken } from "../jwt";

export const getJWTCookie = async () => {
   try {
      const jwt = await getToken();
      const roles = await getRoles();

      return {
         locationId: roles.LocationId,
         userId: roles.UserId,
         token: jwt.Token.token
      }
   } catch (_) {
      return {};
   }
}

export const getJWTToken = async () => {
   const { token } = await getJWTCookie();
   return token;
}

export const getURLParams = () => {
   const params = new URLSearchParams(window.location.search)
   return {
      mode: params.get('mode'),
      meetingId: params.get('meetingId'),
      patientId: params.get('patientId')
   };
}

export const openFullScreen = (element) => {
   if (!element) return

   if (element.requestFullscreen) {
      element.requestFullscreen();
   } else if (element.webkitRequestFullscreen) { /* Safari */
      element.webkitRequestFullscreen();
   } else if (element.msRequestFullscreen) { /* IE11 */
      element.msRequestFullscreen();
   }
}

export const closeFullScreen = () => {
   if (document.exitFullscreen) {
      document.exitFullscreen();
   } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
   } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
   }
}

export const capitalize = (word) => {
   if (!word || !word.trim()) return word;
   if (word.length === 1) return word.charAt(0).toUpperCase();
   return (word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
}

export const scrollDownByRef = (ref) => {
   if (!ref.current) return;

   window.scrollTo({
      top: ref.current?.offsetTop,
      behavior: 'smooth',
   });
};
