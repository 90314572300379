import { getJWTToken } from "../helpers/utils";

export const getLocations = async (freeText) => {
   try {
      const token = await getJWTToken();
      console.log(token);

      const url = `${process.env.REACT_APP_GATEWAY_API_BASE_URL}nhs/ods/getlocations/?freetext=${freeText}`;
      const fecthParams = {
         method: 'get',
         headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
         }
      }

      const response = await fetch(url, fecthParams);

      if (response.status === 200){
         const data = await response.json();
         console.log(data);
         return data.Results;
      }

      else
         return [];

   } catch (_) {
      return [];
   }
}
