import { createContext, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import SigUpForm from "./signup-form";
import SignUpLocation from "./signup-location";

export const SignUpContext = createContext();

const SignUp = () => {
   const [phone, setPhone] = useState("");
   const [location, setLocation] = useState(undefined);

   return (
      <SignUpContext.Provider value={{ phone, setPhone, location, setLocation }}>
         <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
         >
            <section className="wrapper bg-soft-primary">
               <div className="container p-4 text-center">
                  <div className="row">
                     <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
                        <h1 className="display-1 mb-3">New User? Sign Up</h1>

                        <p className="text-start">
                           If you are a new user, feel free to fill-in the
                           following form and be part of the Sonar Health Care
                           Network! By signing up you will have access to a
                           range of free NHS enhanced and advanced services like
                           NMS, MUR, Stop Smoking, Repeat Prescriptions, and
                           also some other tools (Calendar, Library, etc) that
                           will help you provide a better service to your
                           patients.
                        </p>
                     </div>
                  </div>
               </div>
            </section>

            <section className="wrapper bg-light py-3">
               <div className="container py-4 bg-white">
                  <SignUpLocation />
               </div>
            </section>

            <section className="wrapper bg-light py-3">
               <div className="container py-4 bg-white">
                  <SigUpForm />
               </div>
            </section>
         </GoogleReCaptchaProvider>
      </SignUpContext.Provider>
   );
};

export default SignUp;
