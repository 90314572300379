import React from 'react';

const ContactDetails = () => {
   return (
      <>
         <div className="d-flex flex-row">
            <div>
               <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-phone-volume"></i> </div>
            </div>
            <div>
               <h5 className="mb-1">Contact us by phone</h5>
               <p>
                  <b>Sales:</b> +44 (0) 20 8811 2307<br />
                  <b>Support line:</b> +44 (0) 20 8743 9440<br />
                  <b>Fax:</b> +44 (0) 20 8746 0773<br />
                  0830 - 1800 Mondy to Friday<br />
                  1030 - 1500 Saturdays
               </p>
               <p><b>Sundays and out of hours:</b> +44 (0) 20 8811 2307</p>
            </div>
         </div>
         <div className="d-flex flex-row">
            <div>
               <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
            </div>
            <div>
               <h5 className="mb-1">How and where to find us</h5>
               <p>Our head office and operations are based in West London</p>
               <address>
                  9 Goldhawk Road, Shepherds Bush, London, W12 8QQ, UK
               </address>
            </div>
         </div>
         <div className="d-flex flex-row">
            <div>
               <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-envelope"></i> </div>
            </div>
            <div>
               <h5 className="mb-1">Contact us by email</h5>
               <p>
                  <b>Sales:</b> <a href="mailto:pritpal.thind@sonarinformatics.com" className="text-body">pritpal.thind@sonarinformatics.com</a><br />
                  <b>Support line:</b> <a href="mailto:info@sonarinformatics.com" className="text-body">info@sonarinformatics.com</a>
               </p>
            </div>
         </div>
      </>
   )
}

export default ContactDetails;
