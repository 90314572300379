const CPCSTabPharmacies = () => {
   return (
      <>
         <p className="fw-bold">FREE UNTIL APRIL 2023 - REGISTER NOW!</p>
         <p>Sonar has been providing IT and support for pharmacies through a range of services including: CPCS, National Flu and London PPV service, Covid-19 vaccination service, and other NHS Services. From April 2022 pharmacies will go to a provider pays model and need to register with a provider soon.</p>
         <p><span className="fw-bold">From April 2023</span> - CPCS will be free for <a href="https://www.sonarinformatics.com/travel-clinic-and-pgds" className="cpcs-link" target="_blank" rel="noreferrer">Sonar trained PGD users</a>, otherwise a fee of 20p will be charged per completed consultation.</p>
         <span className="badge bg-secondary mr-1">No set up fee</span>
         <span className="badge bg-secondary mr-1">No contract period</span>
         <span className="badge bg-secondary mr-1">Free Until April 2023</span>
         <span className="badge bg-secondary mr-1">Video Consultations</span>
      </>
   );
};

export default CPCSTabPharmacies;
