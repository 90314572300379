import { toast } from 'react-toastify';

const isValidContact = ({ name, phone, email, department, message, agree }) => {
   if (!name || !name.trim()) {
      toast.warning('The Name field is required.');
   } else if (!phone || !phone.trim()) {
      toast.warning('The Phone field is required.');
   } else if (!email || !email.trim()) {
      toast.warning('The Email field is required.');
   } else if (!department || !department.trim()) {
      toast.warning('The Department field is required.');
   } else if (!message || !message.trim()) {
      toast.warning('The Message field is required.');
   } else if (agree !== true) {
      toast.warning('You must accept the terms.');
   } else {
      return true;
   }

   return false;
}

export const saveContact = async (payload) => {
   try {
      if (!isValidContact(payload))
      return false;

      const request = {
         Name: payload.name,
         Phone: payload.phone,
         Email: payload.email,
         Department: payload.department,
         Message: payload.message,
      }

      const url = `${process.env.REACT_APP_NODE_API_BASE_URL}contacts/savecontact`
      const fecthParams = {
         method: 'post',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(request),
      }

      const response = await fetch(url, fecthParams);
      const data = await response.json();

      if (data.errors && data.errors.length > 0) {
         toast.warning(data.errors[0].msg);
         return false;
      }

      toast.success('Contact saved successfully!');
      return true;

   } catch (error) {
      toast.error('Save Contact failed!');
      return false;
   }
}
