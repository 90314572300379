import Highcharts from "highcharts";

const getOptDashboard6 = (chartData, serviceId) => {
   let maxValue = 600000;
   if (serviceId === "1652") {
      maxValue = 10000;
   }
   if (serviceId === "1653") {
      maxValue = 5000;
   }
   if (serviceId === "1651") {
      maxValue = 550000;
   }

   const value = chartData.length > 0 ? Number(chartData[0].Value) : 0;

   return {
      chart: {
         type: "solidgauge",
      },
      title: { text: "" },
      pane: {
         center: ["50%", "65%"],
         size: "100%",
         startAngle: -90,
         endAngle: 90,
         background: {
            backgroundColor:
               (Highcharts.theme && Highcharts.theme.background2) || "#EEE",
            // backgroundColor: "#EEE",
            innerRadius: "60%",
            outerRadius: "100%",
            shape: "arc",
         },
      },
      tooltip: {
         enabled: false,
      },
      plotOptions: {
         solidgauge: {
            dataLabels: {
               y: 5,
               borderWidth: 0,
               useHTML: true,
            },
         },
      },
      yAxis: {
         stops: [
            [0.1, "#DF5353"],
            [0.5, "#DDDF0D"],
            [0.9, "#55BF3B"],
         ],
         lineWidth: 0,
         minorTickInterval: null,
         tickAmount: 0,
         tickInterval: maxValue / 1000,
         tickWidth: 0,
         title: {
            y: -70,
            text: null,
         },
         labels: {
            y: 16,
         },
         min: 0,
         max: maxValue,
      },
      credits: {
         enabled: false,
      },
      series: [
         {
            name: "Number of Jabs",
            data: [value],
            dataLabels: {
               format:
                  '<div style="text-align:center"><span style="font-size:1.5em;color:' +
                  ((Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                     "black") +
                  '">{y:,.0f}</span><br/>' +
                  '<span style="font-size:12px;color:silver">Number of Jabs</span></div>',
            },
            tooltip: {
               valueSuffix: " ",
            },
         },
      ],
      exporting: { enabled: true },
   };
};

export default getOptDashboard6;