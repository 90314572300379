const CPCSUserGuidesGPCPCS = () => {
   return (
      <>
         <h5 className="mb-4">USER GUIDES FOR GPCPCS</h5>

         <ul className="ps-4 mb-0">
            <li className="cpcs-list-item">
               ●&nbsp;
               <a href="https://www.youtube.com/watch?v=DnJJ-WVgQ6s" className='cpcs-link' target="_blank" rel="noreferrer">
                  Video guide
               </a>
            </li>

            <li className="cpcs-list-item">
               ●&nbsp;
               <a href="/docs/user-guides/cpcs/gpcpcs-user-guidev1.0.pdf" className='cpcs-link'>
                  User guide
               </a>
            </li>

            <li className="cpcs-list-item">
               ●&nbsp;
               <a href="/docs/user-guides/cpcs/gpcpcs-sonar-introduction-to-gp-pcnv1.0.pptx" className='cpcs-link'>
                  GP PCN Introduction
               </a>
            </li>

            <li className="cpcs-list-item">
               ●&nbsp;
               <a href="/docs/user-guides/cpcs/gpcpcs-sonar-introduction-to-gp-staff-trainingv1.0.pptx" className='cpcs-link'>
                  Staff Training
               </a>
            </li>
         </ul>
      </>
   );
};

export default CPCSUserGuidesGPCPCS;
