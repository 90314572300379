const CPCSPSNCFAQs = () => {
   return (
      <>
         <h5 className="mb-4">PSNC FAQs</h5>

         <ul className="ps-4 mb-0">
            <li className="cpcs-list-item">
               <a href="https://psnc.org.uk/services-commissioning/advanced-services/community-pharmacist-consultation-service/cpcs-frequently-asked-questions/" className='cpcs-link' target="_blank" rel="noreferrer">
                  ● FAQs
               </a>
            </li>
         </ul>
      </>
   );
};

export default CPCSPSNCFAQs;
