import Highcharts from "highcharts/highstock";
import Dashboard1 from "./dashboard1";
import Dashboard2 from "./dashboard2";
import Dashboard3 from "./dashboard3";
import Dashboard4 from "./dashboard4";
import Dashboard5 from "./dashboard5";
import Dashboard6 from "./dashboard6";

// Load Highcharts modules
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);


const FluService = () => {
   return (
      <section className="wrapper bg-light">
         <div className="container p-3">
            <div className="row">
               <div className="col-md-6 bg-light">
                  <Dashboard6 />
                  <Dashboard1 />
                  <Dashboard3 />
               </div>
               <div className="col-md-6 bg-light">
                  <Dashboard5 />
                  <Dashboard2 />
                  <Dashboard4 />
               </div>
            </div>
         </div>
      </section>
   );
};

export default FluService;
