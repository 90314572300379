
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import { useContext, useState } from "react";
import { getLocations } from "../../services/locations";
import { SignUpContext } from "./signup";

const SignUpLocation = () => {
   const { setPhone, location, setLocation } = useContext(SignUpContext);
   const [isloading, setIsloading] = useState(false);
   const [options, setOptions] = useState([]);
   const [isselected, setIsSelected] = useState(false);

   const filterBy = () => true;

   const handleSearch = async (query) => {
      setIsloading(true);
      const newOptions = await getLocations(query);
      setOptions(newOptions);
      setIsloading(false);
   };

   const handleChange = (selected) => {
      const newLocation = selected[0];
      const address = newLocation.Address;
      const postCode = address.PostCode;
      const entries = Object.entries(address);
      const fullAddress = `${entries
         .filter(([key]) => key.includes("Line"))
         .map(([_, value]) => value)
         .join(", ")} ${postCode}`;
      const fullName = `${newLocation.Name} (${postCode})`;
      newLocation.FullAddress = fullAddress;
      newLocation.FullName = fullName;
      console.log(newLocation);
      setIsSelected(true);
      setLocation(newLocation);
      setOptions([]);
      setPhone(newLocation.Phone);
   };

   const handleCancel = () => {
      setIsSelected(false);
      setLocation(undefined);
   };

   return (
      <div className="container py-4 bg-white">
         {!isselected && (
            <Typeahead
               id="basic-typeahead-single"
               labelKey="Name"
               onInputChange={handleSearch}
               options={options}
               placeholder="Click here and free-type your location name or postcode"
               isLoading={isloading}
               filterBy={filterBy}
               onChange={handleChange}
               renderMenuItemChildren={(option) => (
                  <span>{`(${option.OdsCode}) ${option.Name}, ${option.Address.PostCode}`}</span>
               )}
            />
         )}
         {isselected && (
            <div>
               <p>{location?.FullName}</p>
               <p>{location?.FullAddress}</p>
               <p>
                  Fax: {location?.Fax}, Phone: {location?.Phone}
               </p>
               <button className="btn btn-primary" onClick={handleCancel}>
                  Cancel
               </button>
            </div>
         )}
      </div>
   );
};

export default SignUpLocation;
