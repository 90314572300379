const PocCovid19SignUpDetails = () => {
   return (
      <>
         <p className='mt-0'>
            Thank you for choosing to use Sonar's POC solution for your vaccination clinics. We're delighted to help you deliver the NHS Covid and Flu service.
         </p>
         <p className='mt-4'>
            This form acts as a confirmation that you are requesting services from Sonar Health, which are free for NHS-funded, Covid and Flu Service providers. (Pharmacies run clinics)
         </p>
         <p className='mt-4'>
            At this stage, we need you to:
         </p>
         <p className='mt-4'>
            Provide basic information about your clinic / site<br />
            Confirm the scope of services to be delivered by Sonar Health<br />
            Confirm you're Happy to proceed to proceed- If your currently using another product and move to Sonar , you must NOT use both systems for an individual patient as BSA will reject your payments…<br />
         </p>
         <p className='mt-4'>
            Your email address will be requested at the end, which acts as a digital signature.
         </p>
         <p className='mt-4'>
            After completing this form, we'll be in touch with next steps to get you started asap!
         </p>
         <p className='mt-4'>
            If you need anything from us in the meantime please email info@sonarinformatics.com
         </p>
      </>
   );
};

export default PocCovid19SignUpDetails;
