import { useRef } from "react";
import { scrollDownByRef } from "../../helpers/utils";
import PocCovid19Notes from "./poc-covid19-notes";
import PocCovid19Offer from "./poc-covid19-offer";
import PocCovid19SignUp from "./poc-covid19-signup";

const PocCovid19 = () => {
   const signUpRef = useRef(null);

   const handleScrollToSignUp = () => {
      scrollDownByRef(signUpRef);
   }

   return (
      <>
         <section className="wrapper bg-soft-primary">
            <div className="container pt-10 pb-12 pt-md-14 pb-md-16 text-center">
               <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
                     <h1 className="display-1 mb-3">Sonar PoC Covid-19 Service</h1>
                     <div className="lead px-xxl-10">
                        Pharmacies wishing to use the <b>Sonar Poc Covid-19</b> IT platform must register first!
                        <div className="mt-5">
                           {/* <a className="btn btn-primary" href="https://www.sonarhealth.org/v3/mvform/mvpocform" target="_blank" rel="noreferrer">Click here to register</a> */ }
                           <button className="btn btn-primary" onClick={ handleScrollToSignUp }>Click here to register</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
               <PocCovid19Offer />
            </div>
         </section>

         <section className="wrapper">
            <div className="container pt-14 pt-md-16">
               <PocCovid19Notes />
            </div>
         </section>

         <section className="wrapper" ref={ signUpRef }>
            <div className="container pt-14 pt-md-16" />
         </section>

         <section className="wrapper">
            <div className="container pb-14 pb-md-16">
               <PocCovid19SignUp />
            </div>
         </section>
      </>
   );
};

export default PocCovid19;
