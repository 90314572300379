import { useState } from "react";
import { saveCPCSRegistry } from "../../services/cpcs";

const CPCSForm = () => {
   const [sites, setSites] = useState([1]);

   const changeSites = (number) => {
      const newSites = Array.from({ length: number }, (_, i) => i + 1);
      setSites([...newSites]);
   }

   const handleSubmit = async (e) => {
      e.preventDefault();

      const formData = new FormData(e.target);
      const dataTemp = Object.fromEntries(formData);
      const entries = Object.entries(dataTemp);

      let locations = [];
      entries.filter(([key]) => key.includes("location"))
         .forEach(([key, value]) => {
            const newKey = key.split("-")[1];
            const number = Number(key.split("-")[2]);
            const foundLocation = locations.find((location) => location._id === number);

            if (foundLocation) {
               locations = locations.map((location) => (location._id === number) ? { ...location, [newKey]: value } : location);
            } else {
               locations.push({ _id: number, [newKey]: value });
            }
         });

      const data = Object.fromEntries(entries.filter(([key]) => !key.includes("location")));
      data.locations = locations;

      const result = await saveCPCSRegistry(data);
      if (result) {
         e.target.reset();
         changeSites(1);
      }
   }

   const handleSiteChange = (event) => {
      changeSites(Number(event.target.value));
   }

   return (
      <>
         <h5 className="mb-4">CPCS Registration</h5>

         <p className="mb-3">Please fill in the registration below to confirm you will be using Sonar for CPCS from April 2022:</p>

         <form className="contact-form needs-validation" onSubmit={ handleSubmit }>
            <div className="row">
               <div className="col-lg-6">
                  <div className="row mb-3">
                     <label
                        htmlFor="type"
                        className="col-form-label col-sm-4 py-0"
                     >
                        Type *
                     </label>

                     <div className="col-sm-8">
                        <select
                           id="type"
                           name="type"
                           defaultValue=""
                           className="form-select form-select-sm w-100"
                        >
                           <option value="">- Please Select -</option>
                           <option value="Pharmacies">Pharmacies</option>
                           <option value="GPs">GPs</option>
                        </select>
                     </div>
                  </div>

                  <div className="row mb-3">
                     <label
                        htmlFor="contactName"
                        className="col-form-label col-sm-4 py-0"
                     >
                        Contact Name *
                     </label>

                     <div className="col-sm-8">
                        <input
                           type="text"
                           id="contactName"
                           name="contactName"
                           className="form-control form-control-sm"
                           placeholder="Contact Name"
                        />
                     </div>
                  </div>

                  <div className="row mb-3">
                     <label
                        htmlFor="contactEmail"
                        className="col-form-label col-sm-4 py-0"
                     >
                        Contact Email *
                     </label>

                     <div className="col-sm-8">
                        <input
                           type="email"
                           id="contactEmail"
                           name="contactEmail"
                           className="form-control form-control-sm"
                           placeholder="Contact Email"
                        />
                     </div>
                  </div>
               </div>

               <div className="col-lg-6">
                  <div className="row mb-3">
                     <label
                        htmlFor="organisation"
                        className="col-form-label col-sm-4 py-0"
                     >
                        Organisation *
                     </label>

                     <div className="col-sm-8">
                        <input
                           type="text"
                           id="organisation"
                           name="organisation"
                           className="form-control form-control-sm"
                           placeholder="Organisation"
                        />
                     </div>
                  </div>

                  <div className="row mb-3">
                     <label
                        htmlFor="organisationTradingName"
                        className="col-form-label col-sm-4 py-0"
                     >
                        Org. Trad. Name *
                     </label>

                     <div className="col-sm-8">
                        <input
                           type="text"
                           id="organisationTradingName"
                           name="organisationTradingName"
                           className="form-control form-control-sm"
                           placeholder="Organisation Trading Name"
                        />
                     </div>
                  </div>

                  <div className="row mb-3">
                     <label
                        htmlFor="sites"
                        className="col-form-label col-sm-4 py-0"
                     >
                        N° of Sites *
                     </label>

                     <div className="col-sm-8">
                        <select
                           id="sites"
                           name="sites"
                           defaultValue="1"
                           className="form-select form-select-sm w-100"
                           onChange={ handleSiteChange }
                        >
                           <option value="1">1 Site</option>
                           <option value="2">2 Sites</option>
                           <option value="3">3 Sites</option>
                           <option value="4">4 Sites</option>
                           <option value="5">5 Sites</option>
                           <option value="6">6 Sites</option>
                           <option value="7">7 Sites</option>
                           <option value="8">8 Sites</option>
                           <option value="9">9 Sites</option>
                           <option value="10">10 Sites</option>
                        </select>
                     </div>
                  </div>
               </div>

               <div className="col-12 mt-3 mb-2">
                  <div className="row justify-content-center">
                     <div className="col-sm-2"></div>

                     <label className="col-form-label col-sm-3 text-center py-0">
                        ODS Code
                     </label>

                     <label className="col-form-label col-sm-3 text-center py-0">
                        Location Name
                     </label>

                     <label className="col-form-label col-sm-3 text-center py-0">
                        Post Code
                     </label>
                  </div>
               </div>

               <div className="col-12 mb-3">
                  { sites.map(site => (
                     <div className="row mb-3 justify-content-center" key={ site }>
                        <label
                           htmlFor="organisation"
                           className="col-form-label col-sm-2 py-0"
                        >
                           Location { site } *
                        </label>

                        <div className="col-sm-3">
                           <input
                              type="text"
                              id={ `location-odsCode-${site}` }
                              name={ `location-odsCode-${site}` }
                              className="form-control form-control-sm"
                              placeholder={ `ODS Code ${site}` }
                           />
                        </div>

                        <div className="col-sm-3">
                           <input
                              type="text"
                              id={ `location-name-${site}` }
                              name={ `location-name-${site}` }
                              className="form-control form-control-sm"
                              placeholder={ `Location Name ${site}` }
                           />
                        </div>

                        <div className="col-sm-3">
                           <input
                              type="text"
                              id={ `location-postCode-${site}` }
                              name={ `location-postCode-${site}` }
                              className="form-control form-control-sm"
                              placeholder={ `Post Code ${site}` }
                           />
                        </div>
                     </div>
                  )) }
               </div>
               <div className="col-12">
                  <div className="d-flex justify-content-center">
                     <input type="submit" className="btn btn-primary rounded-pill btn-send px-5 mx-2" value="Submit" />
                  </div>
               </div>
            </div>
         </form>
      </>
   );
};

export default CPCSForm;
