import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/app.css';
import './assets/css/style.css';
import { BrowserRouter } from 'react-router-dom'
import DefaultRoutes from './routes/default-routes'
import Layout from './components/layout/Layout';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const App = () => {
   return (
      <BrowserRouter basename={ baseUrl }>
         <Layout>
            <DefaultRoutes />
         </Layout>
      </BrowserRouter>
   )
}
export default App;
