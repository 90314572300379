import { useRef } from "react";
import { useParams } from "react-router-dom";
import useEffectOnce from "../helpers/hooks/useEffectOnce";
import { scrollDownByRef } from "../helpers/utils";

const UserGuides = () => {
   const generalRef = useRef(null);
   const cpcsRef = useRef(null);
   const gpcpcsRef = useRef(null);
   const covid19Ref = useRef(null);
   const seasonalfluRef = useRef(null);
   const { section } = useParams()

   useEffectOnce(() => {
      if (section === "general") {
         scrollDownByRef(generalRef);
      } else if (section === "cpcs") {
         scrollDownByRef(cpcsRef);
      } else if (section === "gpcpcs") {
         scrollDownByRef(gpcpcsRef);
      } else if (section === "covid19") {
         scrollDownByRef(covid19Ref);
      } else if (section === "seasonalflu") {
         scrollDownByRef(seasonalfluRef);
      }
   }, []);

   return (
      <>
         <section className="wrapper bg-soft-primary">
            <div className="container pt-10 pb-12 pt-md-14 pb-md-16 text-center">
               <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
                     <h1 className="display-1 mb-3">User Guides</h1>
                     <p className="lead px-xxl-10">
                        See below list of user guides
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <div className="container">
            <div className="row gx-0">
               <aside className="col-xl-3 sidebar sticky-sidebar mt-md-0 py-16 d-none d-xl-block">
                  <div className="widget">
                     <nav id="sidebar-nav">
                        <ul className="list-unstyled text-reset">
                           <li><a className="nav-link scroll" href="#general">General user guides</a></li>
                           <li><a className="nav-link scroll" href="#cpcs">CPCS user guides</a></li>
                           <li><a className="nav-link scroll" href="#gpcpcs">GP-CPCS user guides</a></li>
                           <li><a className="nav-link scroll" href="#covid19">Covid-19 user guides</a></li>
                           <li><a className="nav-link scroll" href="#seasonalflu">Seasonal flu user guides</a></li>
                        </ul>
                     </nav>
                  </div>
               </aside>

               <div className="col-xl-8">
                  <section id="general" className="wrapper pt-16" ref={ generalRef }>
                     <div className="card">
                        <div className="card-body p-10">
                           <h2 className="mb-3">General user guides</h2>
                           <ul className="list-unstyled">
                              <li>
                                 <a href={ `${process.env.PUBLIC_URL}/docs/user-guides/general/login-to-sonar-user-guidev1.0.pdf` } target="_blank" rel="noreferrer"><i className="fas fa-file-pdf"></i> How to login to Sonar</a>
                              </li>
                              <li>
                                 <a href="https://www.youtube.com/watch?v=K5syIwbFgt0" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i> SCR 1Click - Setup</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <section id="cpcs" className="wrapper pt-8" ref={ cpcsRef }>
                     <div className="card">
                        <div className="card-body p-10">
                           <h2 className="mb-3">CPCS user guides</h2>
                           <ul className="list-unstyled">
                              <li>
                                 <a href="https://www.youtube.com/watch?v=k22S4G83Q6Y" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i> Video guide</a>
                              </li>
                              <li>
                                 <a href={ `${process.env.PUBLIC_URL}/docs/user-guides/cpcs/cpcs-user-guide-2020v1.4.pdf` } target="_blank" rel="noreferrer"><i className="fas fa-file-pdf"></i> User guide</a>
                              </li>
                              <li>
                                 <a href={ `${process.env.PUBLIC_URL}/docs/user-guides/cpcs/mys-claims-user-guidev1.0.pdf` } target="_blank" rel="noreferrer"><i className="fas fa-file-pdf"></i> MYS claims - User guide</a>
                              </li>
                              <li>
                                 <a href="https://www.youtube.com/watch?v=eJ-ghXYK6M8" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i> CPCS - SCR 1Click</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <section id="gpcpcs" className="wrapper pt-8" ref={ gpcpcsRef }>
                     <div className="card">
                        <div className="card-body p-10">
                           <h2 className="mb-3">GP-CPCS user guides</h2>
                           <ul className="list-unstyled">
                              <li>
                                 <a href="https://www.youtube.com/watch?v=DnJJ-WVgQ6s" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i> Video guide</a>
                              </li>
                              <li>
                                 <a href={ `${process.env.PUBLIC_URL}/docs/user-guides/cpcs/gpcpcs-user-guidev1.0.pdf` } target="_blank" rel="noreferrer"><i className="fas fa-file-pdf"></i> User guide</a>
                              </li>
                              <li>
                                 <a href={ `${process.env.PUBLIC_URL}/docs/user-guides/cpcs/gpcpcs-sonar-introduction-to-gp-pcnv1.0.pptx` } target="_blank" rel="noreferrer"><i className="fas fa-file-powerpoint"></i> GP PCN introduction</a>
                              </li>
                              <li>
                                 <a href={ `${process.env.PUBLIC_URL}/docs/user-guides/cpcs/gpcpcs-sonar-introduction-to-gp-staff-trainingv1.0.pptx` } target="_blank" rel="noreferrer"><i className="fas fa-file-powerpoint"></i> Staff training</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <section id="covid19" className="wrapper pt-8" ref={ covid19Ref }>
                     <div className="card">
                        <div className="card-body p-10">
                           <h2 className="mb-3">Covid-19 user guides</h2>
                           <ul className="list-unstyled">
                              <li>
                                 <a href={ `${process.env.PUBLIC_URL}/docs/user-guides/general/login-to-sonar-user-guidev1.0.pdf` } target="_blank" rel="noreferrer"><i className="fas fa-file-pdf"></i> How to login to Sonar</a>
                              </li>
                              <li>
                                 <a href="https://www.youtube.com/watch?v=K5syIwbFgt0" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i> SCR 1Click - Setup</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <section id="seasonalflu" className="wrapper pt-8 pb-16" ref={ seasonalfluRef }>
                     <div className="card">
                        <div className="card-body p-10">
                           <h2 className="mb-3">Seasonal flu user guides</h2>
                           <ul className="list-unstyled">
                              <li>
                                 <a href={ `${process.env.PUBLIC_URL}/docs/user-guides/general/login-to-sonar-user-guidev1.0.pdf` } target="_blank" rel="noreferrer"><i className="fas fa-file-pdf"></i> How to login to Sonar</a>
                              </li>
                              <li>
                                 <a href="https://www.youtube.com/watch?v=K5syIwbFgt0" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i> SCR 1Click - Setup</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </section>
               </div>
            </div>
         </div>
      </>
   );
};

export default UserGuides;
